import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import FullPostList from '../components/full-post-list'
import { Link } from 'gatsby'

import { graphql } from 'gatsby'

const PostsPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const posts = edges

  const pageTitle = `All ${posts.length} Posts`
  return (
    <Layout pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <a href="/posts" aria-current="page">
              Posts
            </a>
          </li>
        </ul>
      </nav>
      <FullPostList posts={posts} />
    </Layout>
  )
}

export default PostsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [fields___date] }
      filter: { fields: { blog: { eq: true } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
          }
          fields {
            path
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
