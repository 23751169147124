import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const FullPostList = ({ posts }) => (
  <>
    <div className="columns">
      <div className="column">
        {posts.map(({ node }) => (
          <div key={node.id} className="box">
            <h3 className="subtitle">
              <Link to={node.fields.path}>
                {node.frontmatter.title}{' '}
                <span className="has-text-weight-light">
                  — {node.fields.date}
                </span>
              </Link>
            </h3>
            <p>{node.excerpt}</p>
          </div>
        ))}
      </div>
    </div>
  </>
)

FullPostList.propTypes = {
  posts: PropTypes.any,
}

FullPostList.defaultProps = {
  posts: [],
}

export default FullPostList
